import React from "react"
import { useIntl } from "react-intl"
import PortableText from "../../blog/portableText"

export default ({ body }) => {
  const enBody = body._rawEnbody
  const viBody = body._rawVibody
  const intl = useIntl()
  if (!intl) {
    return
  }
  return (
    <div
      className="padding-bottom-md padding-top-md"
      style={{
        backgroundColor: "var(--color-primary)",
        textAlign: "center",
      }}
    >
      <p
        className="section__inner"
        style={{ fontSize: "1rem", fontWeight: 500, color: "white" }}
      >
        {intl.locale === "en" ? (
          <strong>{enBody && <PortableText blocks={enBody} />}</strong>
        ) : (
          <strong>{enBody && <PortableText blocks={viBody} />}</strong>
        )}
      </p>
    </div>
  )
}
