import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RenderSections from "../components/sanity-v2/RenderSections"
import { localized } from "../utils/localized"
import Notification from "../components/sanity-v2/sections/Notification"

const CourseDetailsTemplate = ({ data }) => {
  const sanityContent = data.sanityPage
  const hero = sanityContent.content.filter(
    section => section._type === "hero" || section._type === "heroV2"
  )[0]
  return (
    <>
      {sanityContent.content[0] !== null &&
      sanityContent.content[0]._type == "notification" ? (
        <Notification body={sanityContent.content[0]} />
      ) : (
        <></>
      )}
      <Layout>
        <SEO
          singleLang={sanityContent.singleLang}
          baseUrl={sanityContent.slug.current}
          title={sanityContent.course.title}
          description={
            sanityContent.description ||
            localized(sanityContent.course.descriptionBrief)
          }
          openGraphImage={sanityContent.openGraphImage}
          // image={hero.mainImage.asset.fixed.src}
        />
        <RenderSections
          sections={sanityContent.content}
          course={sanityContent.course}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      description
      openGraphImage {
        ...SanityImageFields
      }
      singleLang
      content {
        ... on SanityDemoProject {
          _type
          _key
          heading {
            vi
            en
          }
          subtitle {
            _key
            _type
            _rawEn
            _rawVi
          }
          textColor
          backgroundColor
          youTubeId
          name {
            vi
            en
          }
          profilePic {
            ...SanityImageFields
          }
          pic1 {
            ...SanityImageFields
          }
          pic2 {
            ...SanityImageFields
          }
          pic3 {
            ...SanityImageFields
          }
          description {
            _key
            _type
            _rawEn
            _rawVi
          }
        }
        ...heroFields
        ... on SanityUpcomingCourse {
          _key
          _type
          heading {
            en
            vi
          }
        }
        ... on SanityTableOfContents {
          _key
          _type
          links {
            title {
              en
              vi
            }
            anchorName
          }
        }
        ...companiesListFields
        ... on SanityTextWithFourIcons {
          _key
          _type
          description {
            en
            vi
          }
          heading {
            vi
            en
          }
          subHeading {
            vi
            en
          }
          technicalIcons {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityCurriculumDetail {
          _key
          _type
          heading {
            vi
            en
          }
          description {
            en
            vi
          }
        }
        ... on SanityYoutubeVideo {
          _type
          _key
          caption {
            en
            vi
          }
          subtitle {
            en
            vi
          }
          youtubeLink
        }

        ... on SanityTextBlock {
          _key
          _type
          heading {
            en
            vi
          }
          description {
            en
            vi
          }
        }
        ... on SanityNotification {
          _key
          _type
          _rawEnbody
          _rawVibody
        }

        ... on SanityGraduateProjectsList {
          _key
          _type
          graduateProjects {
            ...projectFields
          }

          heading {
            en
            vi
          }
        }

        ... on SanityLearningOptionsList {
          _key
          _type
          learningOptions {
            ...learningOptionFields
          }

          heading {
            en
            vi
          }

          ctas {
            openNewTab
            link
            title {
              en
              vi
            }
          }
        }
        ... on SanityCtas {
          _key
          _type
          ctas {
            openNewTab
            link
            size
            color
            title {
              en
              vi
            }
          }
        }

        ... on SanityIconBenefitsList {
          _key
          _type
          heading {
            en
            vi
          }
          benefits {
            ...benefitFields
          }
        }
        ... on SanityEmployeesListFocused {
          _key
          _type
        }
        ... on SanityPaymentOptionsList {
          _key
          _type
          description {
            en
            vi
          }
          heading {
            en
            vi
          }

          paymentOptions {
            _key
            ...paymentOptionFields
          }
        }
        ... on SanityHighlightFeaturesList {
          _key
          _type

          highlightFeatures {
            _key
            ...highlightFeatureFields
          }
        }
        ... on SanityFrequentlyAskedQuestionsList {
          _key
          _type
          heading {
            en
            vi
          }
          questions {
            content {
              en
              vi
            }
            _key
            title {
              en
              vi
            }
          }
        }
        ...whyChooseFields
        ...superSectionFields
        ...heroV2Fields
        ... on SanityGenericCardsList {
          _key
          _type
          heading {
            en
            vi
          }
          cards {
            _key
            title {
              en
              vi
            }
            description {
              en
              vi
            }
            mainImage {
              ...SanityImageFields
            }
          }
        }
        ... on SanityStudentSuccessStory {
          _key
          _type
          title {
            en
            vi
          }
          content {
            _rawEn
            _rawVi
          }
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      course {
        title
        descriptionBrief {
          en
          vi
        }
        curriculumModules {
          content {
            en
            vi
          }
          _key
          title {
            en
            vi
          }
          keywords
        }
        teachers {
          description {
            en
            vi
          }
          jobTitle
          linkedIn
          name
          profilePhoto {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        startDates {
          slug {
            _key
            _type
            current
          }
          startDate(formatString: "MMMM DD")
          endDate(formatString: "MMMM DD")
          content {
            en
            vi
          }
        }
      }
    }
  }
`

export default CourseDetailsTemplate
